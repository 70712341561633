import React, { useEffect } from 'react'
import Menu from '../../components/Menu'
import './Dashboard.css'
import SaleCard from '../../components/SaleCard'
import moment from 'moment'
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; // Importa a localização para português
import api from '../../services/api'
import LoadingBlock from '../../components/LoadingBlock'

export default function Dashboard() {
  dayjs.locale('pt-br')
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [search, setSearch] = React.useState('')

  const [loading, setLoading] = React.useState(false);

  // 0 - dispensacao
  // 1 - em dispensacao
  // 2 - entregando
  // 3 - entregue
  // 4 - cancelado

  const [sales, setSales] = React.useState([])

  useEffect(() => {
    async function getReports() {
      setLoading(true)

        await api.post('api/prescription/list/items', JSON.stringify({
            date_start: date,
            date_end: date
        }))
            .then(response => {
              const sales = response?.data?.schedulings?.data || [];
              //console.log(sales);
              const Sales = sales.map((sale) => {
                return {
                  ...sale,
                  id: sale.id != null ? sale.id : "",
                  street: sale.address != null ? sale.address : "Rua ????",
                  city: sale.city != null ? sale.city : "???????",
                  state: sale.state != null ? sale.state : "??",
                  complement: sale.complement != null ? sale.complement : "",
                  payment_type:
                    sale.payment_type != null ? sale.payment_type : "PIX",
                  date_paid:
                    sale.date_paid != null ? sale.date_paid : "??/??/????",
                  value_total:
                    sale.value_total != null ? sale.value_total : "????",
                  value_paid:
                    sale.value_paid != null ? sale.value_paid : "????",
                  budget:
                    sale.budget != null
                      ? "https://codex.inkless.digital/storage/" + sale.budget
                      : "",
                  payment_time:
                    sale.payment_time != null ? sale.payment_time : "",
                };
              });

              setSales(Sales);
            })
            .catch(error => {
                console.error(error)
            })
            .finally(() => {
              setLoading(false)
            })
    }

    getReports()

  }, [date])

  const screenTela =
    <div id='dashboard' className='screenContainer'>
      <div className='row mt-3 mb-4'>
        <h1 className='title-screen name-screen-title col-12 col-sm-5'>Dashboard</h1>
        <div style={{ gap: 10 }} className='col-12 col-sm-7 flex-end'>
          <input style={{ maxWidth: 165 }} value={date} onChange={(value) => {
            if (value.target.value === '') return
            setDate(value.target.value)
          }} type="date" placeholder="Pesquisar Cliente" className="form-control input-pesquisa-non-focus" />

          <div className="name-search ">
            <input value={search} onChange={(value) => {
              setSearch(value.target.value)
            }} type="text" placeholder="Pesquisar Cliente" className="form-control input-pesquisa-non-focus" />
          </div>
        </div>
      </div>

      {
        loading ? (
          <LoadingBlock />
        ) : (
          sales.length > 0 ? (
            sales.map((elem, index) => {
              return (
                <SaleCard key={index} index={index} sale={elem} sales={sales} setSales={setSales} />
              )
            })
          ) : (
            <div className='no-sales row align-items-center'>
              <h3 className='text-center title-screen'>Sem vendas na data especificada</h3>
            </div>
          )
        )
      }

    </div>

  return (
    <Menu screen={screenTela} />
  )
}
