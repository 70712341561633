import React from 'react'
import Menu from '../../components/Menu'
import './Financial.css'
import 'dayjs/locale/pt-br'; // Importa a localização para português
import { faSackDollar, faScaleBalanced, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
export default function Financial() {

  const screenTela =
    <div id='dashboard' className='screenContainer'>
      <div className='row mt-3 mb-4'>
        <h1 className='title-screen name-screen-title col-12 col-sm-5 mb-4'>
          <FontAwesomeIcon style={{ marginRight: 20, color: 'var(--color1Ink)' }} icon={faFileInvoiceDollar} size="md" />
          <span>Financeiro</span>
          </h1>
        <div style={{ gap: 10 }} className='col-12 col-sm-7 flex-end'>
        </div>
      </div>
      <div className='container-item-card'>

        <Link to="/financial/report/sales" className='item-card'>
          <FontAwesomeIcon style={{ marginRight: 5 }} icon={faSackDollar} color="white" size="xl" />
          <span>Relatório de Vendas</span>
        </Link>

        <Link to="/financial/report/balance" className='item-card'>
          <FontAwesomeIcon style={{ marginRight: 5 }} icon={faScaleBalanced} color="white" size="xl" />
          <span>Relatório de Repasse</span>
        </Link>
      </div>



    </div>
  React.useEffect(() => {

  }, [])
  return (

    <Menu screen={screenTela} />


  )
}
